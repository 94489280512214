<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <!-- end test -->
        <div class="va-card">
          <div class="row">
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <h4 class="menu_header">Rules</h4>
                  <div class="">
                    <div class="btn-toolbar" v-if="user.role.id == 1 || user.role.id == 2">
    
                     
                      <router-link :to="{ name: 'loyalty_rule_setup'}">
                        <button class="btn btn-default custom_btn custon_orange">
                          <i class="fa fa-plus"></i> New Rule
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="search_section">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group has-search mt-1">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="search" @keyup="searchItems()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..." class="form-control">
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group has-search mt-1">
                        <button class="btn btn-orange search">Search</button>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="row">
                          
                    <div class="col-md-6">
                      <select class="form-control mt-1" @change="searchItems()" v-model="typeFilter">
                        <option value="">All</option>
                        <option :value="true">Sold on its own</option>
                        <option :value="false">Not sold on its own</option>
                      </select>
                    </div>
                  </div> -->
                </div>
              </div>
              <hr>
            </div>
            <div class="col-lg-12">
              <div class="dash_card_body pt-0">
                <div class="table_section">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Key</th>
                        <th scope="col">Points</th>
                        <th scope="col">Based on</th>
                        <th scope="col">Description </th>
                        <th scope="col">Last Updated</th>
                      </tr>
                    </thead>
                    <tbody v-if="!loading && rules.length > 0">
                      <tr v-for="rule in rules" :key="rule.id">
                        <td>
                          <span>{{getItemName(rule.key)}}</span>
                        </td>
                        <td>
                          <span>{{rule.points}}</span>
                        </td>
                        <td>
                          <span>{{rule.based_on}}</span>
                          <span v-if="rule.based_on == 'quantity'"> &nbsp;
                            <b> <small>{{rule.quantity}}</small> </b>
                          </span>
                        </td>
                        <td>
                          {{rule.description}}
                        </td>
                        <td>
                          <span>{{formatDate(rule.updated_at,1)}}</span>
                        </td>
                      </tr>
                     
                    </tbody>
                    <tbody v-if="!loading && rules.length == 0">
                      <tr>
                        <td colspan="5" style="text-align:center"> No Data</td>
                      </tr>
                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="8">
                          <div id="pagination" v-if="totalPages > 1">
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                </select>
                              </li>
    
                            </ul>
                          </div>
    
                        </td>
                      </tr>
                    </tfoot>
                  </table>
    
                </div>
              </div>
              <div v-if="loading" class="loading-cover">
                <div class="loader"> </div>
              </div>
    
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
    import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
    import ReusableDataMixins from '@/mixins/ReusableDataMixins';
    import Pagination from '@/pages/pagination/pagination.vue';
    export default {
        components: {
            Pagination,
        },
        data() {
            return {
                rules: [],
                loading: false,
                page: 1,
                search: '',
                totalPages: 0,
                totalRecords: 0,
                recordsPerPage: 10,
                debounce: null,
                menuItems: [],
                menuspinner: "<i class='fa fa-spinner fa-spin '></i>"
            }
        },
        mounted: async function () {
            this.getRules();
            this.getItems();  
        },
        methods: {
            getItemName(key){
              let item = this.menuItems.filter((x) => x.metadata.pos_id == key)
              if(item !=undefined && item.length !=0){
                return item[0].menu_item.item_name
              }else{
                item = this.menuItems.filter((x) => x.metadata.id == key)
                if(item !=undefined && item.length !=0){
                  return item[0].menu_item.item_name
                }else{
                  return key
                }
              }

            },
            searchItems() {
                clearTimeout(this.debounce);
                this.debounce = setTimeout(() => {
                    this.getRules();
                }, 1500)
            },
            async getItems() {
            let url = `${this.baseUrl}/menu_item`;
            url = new URL(url);
            url.searchParams.set('merchant_id', this.merchantID);
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            this.axios.get(url, config).then((response) => {
                this.menuItems = response.data.data
            }).catch((error) => {
              if (error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
            })
        },
            async getRules() {
                this.loading = true;
                this.rules = [];
                let url = `${this.dashBaseUrl}/loyalty-actions`;
                url = new URL(url);
                url.searchParams.set('page', this.page);
                url.searchParams.set('size', this.recordsPerPage);
                url.searchParams.set('merchant_id', this.merchantID);
                if(this.search !==''){
                    url.searchParams.set('search_param',this.search);
                }

                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                this.axios.get(url, config).then((response) => {
                    if (response.status === 200) {
                        this.loading = false;
                        if(response.data.data.data != undefined){
                          this.rules = response.data.data.data;
                        }
                        this.totalPages = Math.ceil(response.data.data.total / this.recordsPerPage)
                        this.totalRecords = response.data.data.total_count
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        this.$router.push({
                            name: 'login'
                        });
                    }
                }).finally(() => {
                    this.loading = false;
                    this.searchLoading = '';
                })
            },
            onPageChange(page) {
                this.page = page
                this.getRules();
            },
            onChangeRecordsPerPage() {
                this.getRules();
            },
        },
        mixins: [ReusableFunctionMixins, ReusableDataMixins]
    }
    </script>
    